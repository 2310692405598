var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"splash",staticStyle:{"position":"fixed","top":"0","left":"0","height":"var(--app-height)","z-index":"999999","background":"#39FF14","transition":"width .5s ease","overflow":"hidden"},style:({width: _vm.isSplash ? '100vw' : '0px'})},[_c('div',{staticStyle:{"position":"relative","width":"100vw","height":"var(--app-height)","display":"flex","flex-direction":"column","align-items":"center"}},[_c('div',{staticClass:"fixed-content",staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","width":"100%","min-height":"60px","display":"flex","align-items":"center","justify-content":"center","animation":"none"}},[_c('div',{ref:"title",staticClass:"pageTitle",class:{
                    'p22': _vm.pageTitle == 'HOME',
                    't--title ': _vm.pageTitle != 'HOME'
                },staticStyle:{"letter-spacing":"-0.1em","text-align":"center"},style:({
                    letterSpacing: _vm.pageTitle == 'HOME' ? '-0.05em' : '-0.1em'
                })},[(_vm.pageTitle)?_c('span',[_vm._v(_vm._s(_vm.pageTitle))]):_vm._e()])]),_c('div',{staticStyle:{"position":"relative","width":"100%","height":"var(--app-height)","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('div',{staticStyle:{"height":"60px"},style:({
                    paddingTop: !_vm.isMobile ? '30px' : '15px'
                })},[_c('Marquee',{staticClass:"t--kor",attrs:{"direction":Math.sign(_vm.deltaY) == 1 ? 'down' : 'up',"gap":50,"timeScale":0.6,"reverse":false},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"t--700 t--kor",staticStyle:{"display":"flex","align-items":"center","font-weight":"600","text-align":"center"}},[_c('p',{staticStyle:{"line-height":"1"}},[_vm._v("WE C")]),_c('div',{staticStyle:{"line-height":"1","animation-duration":"1s","animation-timing-function":"linear","animation-iteration-count":"infinite"},style:({
                                    animationName: Math.sign(_vm.deltaY) == 1 ? 'rotate-left' : 'rotate-right',
                                    height: !_vm.isMobile ? '40px' : '20px'
                                })},[_c('img',{style:({
                                        width: !_vm.isMobile ? '40px' : '20px'
                                    }),attrs:{"src":require("@/assets/images/official/icon/star_black.png"),"alt":"기업 로고 아이콘"}})]),_c('p',{staticStyle:{"line-height":"1"}},[_vm._v("DE, YOU EXCEL™")])])]},proxy:true}])})],1),_c('div',{staticStyle:{"width":"100%","flex":"1 0 auto","display":"flex","flex-direction":"column","align-items":"flex-end","justify-content":"flex-start"},style:({
                padding: !_vm.isMobile ? '60px 50px 124px 50px' : '0 20px 60px 20px'
            })},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"flex-start","justify-content":"space-between","z-index":"2"},style:({
                        padding: !_vm.isMobile ? '20px 0' : '10px 0'
                    })},[_vm._m(0),_vm._m(1)]),_c('div',{staticStyle:{"height":"8em"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t--500",staticStyle:{"font-family":"'GothicA1'","line-height":"1.1","font-weight":"600"}},[_vm._v(" BEHAVIORS"),_c('br'),_vm._v(" SHAPE"),_c('br'),_vm._v(" TOMORROW™ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t--400",staticStyle:{"font-family":"'GothicA1'","line-height":"1.1","text-align":"right"}},[_vm._v(" Est. Sep"),_c('br'),_vm._v("©2K23 ")])
}]

export { render, staticRenderFns }