/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"

const mutations = {
  SET_IS_SPLASH(state, payload) {
    state.isSplash = payload
  },
  SET_IS_MOBILE(state, payload) {
    state.isMobile = window.innerWidth < 768
  },
  SET_IS_SCROLL_CHANGED(state, payload) {
    state.isChangedHeight = payload
  },
  SET_DARKMODE(state, payload) {
    state.isDarkMode = payload
  },
  SET_TOAST(state, payload) {
    state.toast = payload
  },
  TOGGLE_SWITCH(state, payload) {
    state.switchState = payload
  },
  TOGGLE_TOAST_STATE(state, payload) {
    state.toast.state = payload
  },
  SET_SCROLL_POSITION(state, payload) {
    state.scrollY = payload
  },
  SET_SCROLL_DISTANCE(state, payload) {
    state.distanceY = payload
  },
  STOP_SCROLL(state, payload) {
    state.preventScroll = payload
  },
  SET_PRINT_INFO(state, payload) {
    state.isPrint = payload
  },
  SET_ARCHIVE_MODAL_STATE(state, payload) {
    state.isArchive = payload
  },
  SET_INNER_WIDTH(state, payload) {
    state.innerWidth = payload
  },
  SET_INNER_HEIGHT(state, payload) {
    state.innerHeight = payload
  },
  TOGGLE_HAMBURGER_STATE(state, payload) {
    state.isHamburger = payload
  },
  SET_ACTIVE_MENU(state, payload) {
    state.activeMenu = payload
  },
  SET_IS_STICKY(state, payload) {
    state.isStickyArea = payload
  },
  SET_STICKY_SCROLL_Y(state, payload) {
    state.stickyY += payload
  },
  SET_SCROLL_DELTA_Y(state, payload) {
    state.deltaY = payload
  },
  SET_CURSOR_TYPE(state, payload) {
    state.cursorType = payload
  },
  TOGGLE_IS_OPEN_RIGHTNAV(state, payload) {
    state.isRightNav = payload
  },
  SET_CUNSULT_INDEX(state, payload) {
    state.cunsultIndex = payload
  },
  SET_CHATBOX_TYPE(state, payload) {
    state.isChatbox = payload
  },
  SET_CHAT_HISTORY(state, payload) {
    state.chatHistory.push(payload)
  },
  SET_ALERT_HISTORY(state, payload) {
    state.alertList.push(payload)
  },
  RESET_CHAT_HISTORY(state) {
    state.chatHistory = []
  },
  RESET_ALERT_HISTORY(state) {
    state.alertList = []
  },
  SET_IS_CUSTOMER_MODAL(state, payload) {
    state.isCustomerModal = payload
  },
  SET_IS_PRODUCT_MODAL(state, payload) {
    state.isProductModal = payload
  },
  SET_IS_HIDE(state, idx) {
    Vue.set(state.alertList[idx], 'isHide', true)
  },
  DELATE_ALERT(state, idx) {
    Vue.delete(state.alertList, idx)
  },
  EDIT_TASK_STATE(state, payload) {
    var index = state.taskList.findIndex((item) => {
      return payload.id == item.id
    })
    if(index != -1) {
      Vue.set(state.taskList[index], 'state', payload.state)
    }
  },
  SET_ACTIVE_TASK_LIST(state, payload) {
    state.activeTaskList = JSON.parse(JSON.stringify(state.taskList)).filter((item) => {
      return payload == item.company_id
    })
  },
  SET_ACTIVE_TASK(state, payload) {
    state.activeTask = payload
  },
  SET_ACTIVE_NOTE_LIST(state, payload) {
    state.activeNoteList = JSON.parse(JSON.stringify(state.noteList)).filter((item) => {
      return payload == item.task_id
    })
    console.log(state.activeNoteList)
  },
  SET_ACTIVE_BOM(state, payload) {
    state.activeBom = payload
  },
  SET_ACTIVE_PROCESS(state, payload) {
    state.activeProcess = payload
  },
  SEARCH_STOCK_LIST(state, payload) {
    state.stockKeyword = payload
  },
  SEARCH_PROCESS_LIST(state, payload) {
    state.processKeyword = payload
  },
  SET_ACTIVE_PROCESS_LIST(state, payload) {
    if(payload) {
      state.activeProcessList = JSON.parse(JSON.stringify(state.processList)).filter((item) => {
        return item.product_id == payload
      })
    } else {
      state.activeProcessList = JSON.parse(JSON.stringify(state.processList))
    }
  },
  SET_ACTIVE_PRODUCT(state, payload) {
    if(payload.key == 'bom') {
      state.activeBom = payload.query
    } else {
      state.activeProcess = payload.query
    }
  },
  SET_REQUEST_LIST(state, payload) {
    state.requestList = payload
  },
  SET_IS_STUCK(state, payload) {
    state.isStuck = payload
  },
  PERMIT_COUNT_SLIDE_INDEX(state, payload) {
    state.permitCount = payload
  },
  SET_WEIGHT(state, payload) {
    state.weight = payload
  },
}

  export default mutations
