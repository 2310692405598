import Vue from 'vue';
import App from './App.vue'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css';
import Day from 'dayjs'

import axios from "./axios.js"

Vue.prototype.$http = axios

Vue.config.productionTip = false

import store from './store/store'

import router from './router/index'
import vuetify from './plugins/vuetify'


import AOS from "aos";
import "aos/dist/aos.css";

import Meta from 'vue-meta';

import VueAwesomeSwiper from 'vue-awesome-swiper';

import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

Vue.use(VueAwesomeSwiper);

Vue.use(Meta, {
    attribute: 'data-vue-meta',
    tagIDKeyName: 'vmid'
  });
Vue.use(Day)
Vue.use(Vuesax)
Vue.use(
    AOS.init({
        startEvent: "load",
    })
)

// As a plugin
import VuePrlx from 'vue-prlx'
Vue.use(VuePrlx);

// Or as a directive
import { VuePrlxDirective } from 'vue-prlx'
Vue.directive('prlx', VuePrlxDirective);

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
require("dayjs/plugin/isSameOrBefore")

Day.extend(isSameOrBefore)

import VueKakaoSdk from 'vue-kakao-sdk'

require('@/assets/css/basic.css')
require('@/assets/css/font.css')
require('@/assets/css/transition.css')
require('@/assets/scss/root.scss')
require('@/assets/scss/main.scss')
require('@/assets/scss/chat.scss')
require('@/assets/scss/work.scss')
require('@/assets/scss/service.scss')
require('@/assets/scss/contact.scss')

const apiKey = 'a00045c4b49c7f9be84e8b976e1af322';
Vue.use(VueKakaoSdk, { apiKey });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
